var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "suppliers-map-container" },
    [
      _vm.mounted
        ? [
            _c(_vm.$store.user.chineese ? "baidu" : "google", {
              tag: "component",
              attrs: {
                data: _vm.data,
                countries: _vm.countries,
                "marker-content": _vm.markerContent,
              },
              on: { init: _vm.init },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "suppliers-map-container__loader",
        class: {
          "suppliers-map-container__loader--loading":
            !_vm.initialized || _vm.loading,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }