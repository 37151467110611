<template>
  <div class="suppliers-map-container">
    <template v-if="mounted">
      <component
        :is="$store.user.chineese ? 'baidu' : 'google'"
        @init="init"
        :data="data"
        :countries="countries"
        :marker-content="markerContent"
      ></component>
    </template>
    <div
      class="suppliers-map-container__loader"
      :class="{
        'suppliers-map-container__loader--loading': !initialized || loading,
      }"
    ></div>
  </div>
</template>

<script>
const baidu = () => import('./baidu.vue');
const google = () => import('./google.vue');

export default {
  props: {
    countries: {
      type: Array,
    },
    data: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    markerContent: {
      type: Function,
    },
  },
  components: { baidu, google },
  data() {
    return {
      mounted: false,
      initialized: false,
    };
  },
  methods: {
    init() {
      this.initialized = true;
    },
  },
  mounted() {
    this.mounted = true;
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';

.suppliers-map-container {
  position: relative;

  // Based on loader (see frame/index.vue);
  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    background: rgba($c-light, 0.95);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease, visibility 0.2s ease;
    &--loading {
      visibility: visible;
      opacity: 1;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - #{math.div(1rem, 1.6)});
      top: calc(50% - #{math.div(1rem, 1.6)});
      height: math.div(2rem, 1.6);
      width: math.div(2rem, 1.6);
      border: 2px solid $c-blue;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      animation: spinAround 500ms infinite linear;
    }
  }
}
</style>
